import Swiper, { Manipulation, Navigation } from 'swiper';
import 'swiper/swiper.scss';

$( document ).ready(function() {
    let swiperExist = $(".swiper");
    
    const swiper = new Swiper('.swiper', {
        modules: [ Manipulation, Navigation ],
        breakpoints: {
            // when window width is >= 320px
            1440: {
                slidesPerView: 2.10,
            },
        },
        freeMode: true,
        direction: 'vertical',

        loop: false,
        keyboard: {
            enabled: true,
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
    
    

    $('.util-element').on('click', function (el) {
        const activeButton = $('.button-active')[0];
        let impShapes = document.getElementsByClassName('imp-shape');
        if (activeButton === undefined) {
            for (let item of impShapes) {
                let polygonId = item.id;
                let elementId = $("div").find(`[data-id='${polygonId}']`);
                elementId.removeClass('hidden');
                item.classList.remove('hidden');
            }
        }
        else {
            const activeButton = $('.button-active')[0];
            const buttonText = activeButton.getAttribute('data-util-title').toLowerCase();
            for (let item of impShapes) {
                let polygonId = item.id;
                let elementId = $("div").find(`[data-id='${polygonId}']`);
                elementId.removeClass('hidden');
                item.classList.remove('hidden');
                let shapeTitle = item.getAttribute('data-shape-title');
                let utilTitle = shapeTitle.split('-')[1];
                if (buttonText !== utilTitle) {
                    elementId.addClass('hidden');
                    item.classList.add('hidden');
                }
            }
        }
    });

    // Filter buttons - Change image on click
    const utilImgs = document.querySelectorAll('.util-img');
    if(utilImgs !== null) {
        utilImgs.forEach(utilImg => {
            utilImg.addEventListener('click', function (el) {
                let prevTarget = document.querySelector('.button-active');
                if(prevTarget !== null) {
                    let prevTargetData = prevTarget.getAttribute('data-img-type');
                    prevTarget.children[0].src="/assets/images/" + prevTargetData +'.png';
                }
                $('.util-element').removeClass('button-active');
                let parentElement = el.target.parentElement;
                if(parentElement !== prevTarget) {
                    parentElement.classList.toggle('button-active');
                    el.target.src= "/assets/images/" + parentElement.getAttribute('data-img-type') + "-yellow" +'.png';
                }
            });
        });
    }

    const modalVideo = document.querySelector('.modal-video-js');
    const playButton = document.querySelectorAll('.play-btn');
    if(playButton !== null) {
        playButton.forEach(playButton => {
            playButton.addEventListener('click', () => {
                modalVideo.classList.add('hidden');
                modalVideo.classList.remove('hidden');
            });
        });
    }
  
    if(modalVideo !== null) {
        let closeButton = modalVideo.querySelectorAll('.btn-close');
        if(closeButton !== null) {
            closeButton.forEach(closeButton => {
                closeButton.addEventListener('click', () => {
                    modalVideo.classList.toggle('hidden');
                    let videoElement = $('#util-video-element');
                    if (videoElement !== undefined) {
                        videoElement.attr('src', '');
                    }
                });
            });
        }
    }
 
    document.addEventListener('mouseup', function(e) {
        if(modalVideo != null) {
            if (!modalVideo.contains(e.target)) {
                document.addEventListener('mouseup', function(e) {
                    if (!modalVideo.contains(e.target)) {
                        if(!modalVideo.classList.contains('hidden')) {
                            modalVideo.classList.toggle('hidden');
                            let videoElement = $('#util-video-element');
                            if (videoElement !== undefined) {
                                videoElement.attr('src', '');
                            }
                        }
                    }
                });
            }
        }
    });
  
    const swiperNext = document.querySelector('.swiper-button-next')
    if(swiperNext !== null) {
        swiperNext.addEventListener("click", function () {
            swiper.slideNext();
        });
    }

    const swiperPrev = document.querySelector('.swiper-button-prev')
    if(swiperNext !== null) {
        swiperPrev.addEventListener("click", function () {
            swiper.slidePrev();
        });
    }
    
    const swiperButtons = document.querySelector('.swiper-buttons');
    if(swiperButtons !== null) {
        swiperButtons.classList.add('hidden');
        if (window.innerWidth < 1439) {
            swiper.destroy();
            swiperButtons.classList.add('hidden');
        } 
    }

    //Destroy swiper if screen less then 1440
    function resize() {
        var window = document.body.clientWidth;
        if (window < 1440) {
            swiper.destroy();
            document.querySelector('.swiper-buttons').classList.add('hidden');
        }
    }
    
    $(window).resize(function() {
        let swiperExisit = $(".swiper");
        if(swiperExisit.length > 0) {
            resize();
        }
    });

    // Reset hash after refresh.
    if (window.location.hash !== "") {
        history.pushState("", document.title, window.location.pathname + window.location.search);
    }
    
    let prevActiveImageClass = $(".active-image-map");
    prevActiveImageClass.addClass("hidden").removeClass("active-image-map");
  
    // Change displayed tab in util-modal.
    $('.button-modal-switch').on('click', function () {
        $('.button-modal-switch').removeClass('button-active-modal-switch');
        $(this).addClass('button-active-modal-switch');
    });
    
    $('#map-video-button').on('click', function () {
        $('#map-video').removeClass('hidden');
        $('#map-lineup').addClass('hidden');
    });
    
    $('#map-lineup-button').on('click', function () {
        $('#map-lineup').removeClass('hidden');
        $('#map-video').addClass('hidden');
    });
  
    // Fill util-modal with values.
    $('.util-video-button').on('click', function () {
        $('#map-lineup').addClass('hidden');
        $('#map-video').removeClass('hidden');
        $('#map-lineup-button').removeClass('button-active-modal-switch');
        $('#map-video-button').addClass('button-active-modal-switch');

        var utilTitle = $(this).attr("data-util-map");
        var utilTo = $(this).attr("data-util-to");
        var utilFrom = $(this).attr("data-util-from");
        var videoUrl = $(this).attr("data-video-url");
        var lineup = $(this).attr("data-lineup-url");
        var utilType = $(this).attr("data-util-type");
        var utilMovement = $(this).attr("data-util-movement");
        var utilTechnique = $(this).attr("data-util-technique");
    
        if (utilTitle !== undefined && utilTo !== undefined && utilFrom !== undefined) {
            $('#modalLabel').text(utilTitle + " " + utilTo + " " + utilType.toLowerCase() + " from " + utilFrom);
        }
        if (utilType !== undefined) {
            $('#util-type').text(utilType);
        }
        if (utilMovement !== undefined) {
            $('#util-movement').text(utilMovement);
        }
        if (utilTechnique !== undefined) {
            $('#util-technique').text(utilTechnique);
        }
        if (videoUrl !== undefined) {
            let videoElement = $('#util-video-element');
            if (videoElement !== undefined) {
                videoElement.attr('src', 'https://www.youtube.com/embed/' + videoUrl + '?autoplay=1&loop=1&rel=0&color=white&mute=1');
            }
        }
        console.log(lineup)
        if (lineup !== undefined) {
            $('#lineup-image').attr('src', lineup);
        } 
        if(lineup !== "") {
            $('.button-modal-switch-container').removeClass('hidden');
        }
        else {
            $('.button-modal-switch-container').addClass('hidden');
        }
    });
});

$( window ).on('hashchange', function() {
    let prevActiveImageClass = $('.active-image-map');
    if (prevActiveImageClass.length > 0) {
        prevActiveImageClass.addClass('hidden');
        prevActiveImageClass.removeClass('active-image-map');
    }
   
    const hash = window.location.hash.substring(1)
    if (hash) {
        const currentActiveImageClass = $("." + hash);
        if (currentActiveImageClass !== null) {
            currentActiveImageClass.addClass('active-image-map');
            currentActiveImageClass.removeClass('hidden');
            const index = getSwiperLength();
            hideSwiperNavigationIfHaveElements(index);
        }
    }
}).trigger('hashchange');

function getSwiperLength() {
    var index = 0;
    const swiper = document.querySelector('.swiper').swiper
    if(swiper !== null && swiper !== undefined) {
        swiper.update();
        for(let i = 0; i < swiper.slides.length; i++) {
            if(swiper.slides[i].classList.contains('active-image-map')) {
                index += 1;
            }
        }
        return index;
    }
    else return 0;
}

function hideSwiperNavigationIfHaveElements(index) {
    const swiperButtons = document.querySelector('.swiper-buttons');
    if(index < 3) {
        swiperButtons.classList.add('hidden');
    }
    else {
        swiperButtons.classList.remove('hidden');
    }
}


